import React from "react";
import { Box, Typography } from "@material-ui/core";
import ConnectionButton from "../../components/room/ConnectionButton";
import { useNavigate } from "react-router-dom";
import Logo from "../../components/Logo";

const Intro = () => {
  const navigate = useNavigate();
  return (
    // MUI card component in the middle of the screen
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {false && (
        <Box
          sx={{
            width: "100%",
            maxWidth: 500,
            height: "350px",
            boxShadow: "1px 1px 12px #00000034",
            padding: "20px",
            pt: 20,
          }}
        >
          <ConnectionButton
            buttonTitle="Join Room"
            onClickHandler={() => navigate("/join-room")}
            variant="contained"
          />
          <ConnectionButton
            buttonTitle="Create Room"
            onClickHandler={() => navigate("/join-room?host=true")}
            variant="outlined"
          />
        </Box>
      )}
    </Box>
  );
};

export default Intro;
