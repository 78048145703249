import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL + "/v3";

const handleApi = async (url, method, data) => {
  // const token = localStorage.getItem("token");
  const headers = {
    "Content-Type": "application/json",
    // Authorization: `Bearer ${token}`,
  };

  return axios({
    url,
    method,
    data,
    headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export default handleApi;
