import { Box } from "@material-ui/core";
import LogoImg from "../images/logo.png";
const Logo = (props) => (
  <Box
    component="img"
    src={LogoImg}
    sx={{
      height: 40,
      width: "auto",
      ...props.sx,
    }}
    {...props}
  />
);

export default Logo;
