let restartTimer = null;
let forceRestartTimeout = null;  // Timeout for forceful restart
let isRecognitionRunning = false;  // Track if recognition is running
let speechRecognizer;  // Store the instance of the speech recognizer

export const startTranscription = (onTranscriptionCallback) => {
    if (typeof window !== 'undefined' && 'webkitSpeechRecognition' in window) {
        speechRecognizer = new window.webkitSpeechRecognition();
        speechRecognizer.continuous = true;
        speechRecognizer.interimResults = true;
        speechRecognizer.lang = 'en-US';

        let finalTranscripts = '';

        const startRecognition = () => {
            if (!isRecognitionRunning) {
                speechRecognizer.start();
                isRecognitionRunning = true;
                console.log("Speech recognition started");

                // Set a forceful restart timeout to stop and restart after inactivity
                resetForceRestart();
            }
        };

        const resetForceRestart = () => {
            if (forceRestartTimeout) {
                clearTimeout(forceRestartTimeout);
            }
            // Force stop and restart the recognition every 10 seconds (adjust as needed)
            forceRestartTimeout = setTimeout(() => {
                if (isRecognitionRunning) {
                    console.log("Force restarting recognition due to inactivity...");
                    speechRecognizer.stop();
                }
            }, 10000);  // Adjust this time as per requirement (10 seconds for testing)
        };

        speechRecognizer.onresult = (event) => {
            let interimTranscripts = '';
            for (let i = event.resultIndex; i < event.results.length; i++) {
                let transcript = event.results[i][0].transcript;
                transcript.replace("\n", "<br>");

                if (event.results[i].isFinal) {
                    // Instead of appending, handle final transcripts differently
                    finalTranscripts = transcript; // Only keep the latest final transcript

                    if (onTranscriptionCallback) {
                        onTranscriptionCallback(finalTranscripts); // Sentiment analysis only on the most recent final transcript
                    }
                } else {
                    interimTranscripts += transcript;
                }
            }
        };

        speechRecognizer.onerror = (event) => {
            console.error('Speech recognition error:', event.error);
            isRecognitionRunning = false;  // Mark it as not running to allow restart
            // Restart on any error after a brief delay
            setTimeout(startRecognition, 1000); // Delay before restart
        };

        speechRecognizer.onend = () => {
            console.log("Speech recognition ended.");
            isRecognitionRunning = false;

            // Restart recognition with a slight delay to ensure it's fully stopped
            setTimeout(startRecognition, 1000); // Delay before restart
        };

        // Start the speech recognition initially
        startRecognition();

        // Periodically check and restart the recognition if necessary
        restartTimer = setInterval(() => {
            if (!isRecognitionRunning) {
                console.log("Timer: Restarting speech recognition");
                startRecognition();
            }
        }, 5000);  // Check every 5 seconds

        return speechRecognizer;
    } else {
        console.log('Your browser does not support Speech Recognition. Please use Google Chrome.');
        return null;
    }
};

// Clear timers when stopping transcription
export const stopTranscription = () => {
    if (restartTimer) {
        clearInterval(restartTimer);
        restartTimer = null;
    }
    if (forceRestartTimeout) {
        clearTimeout(forceRestartTimeout);
        forceRestartTimeout = null;
    }
    if (speechRecognizer && isRecognitionRunning) {
        speechRecognizer.stop();  // Ensure to stop recognition
    }
    isRecognitionRunning = false;  // Reset the running flag
};
