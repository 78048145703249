import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import GuestGuard from "./components/GuestGuard";
import MainLayout from "./components/MainLayout";
import Login from "./Pages/authentication/Login";
import Register from "./Pages/authentication/Register";
import Home from "./Pages/Home";
import NotFound from "./Pages/NotFound";
import LoadingScreen from "./components/LoadingScreen";
import Intor from "./Pages/intro/Intro";
const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const JoinRoom = Loadable(lazy(() => import("./Pages/join-room/JoinRoom")));
const Room = Loadable(lazy(() => import("./Pages/room/Room")));
const LeaveRoom = Loadable(lazy(() => import("./Pages/leave-room/LeaveRoom")));

const routes = [
  {
    path: "authentication",
    element: <MainLayout />,
    children: [
      {
        path: "login",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: "register",
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        ),
      },
    ],
  },
  {
    path: "*",
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <Intor />,
      },
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "join-room",
        element: <JoinRoom />,
      },
      {
        path: "leave-room",
        element: <LeaveRoom />,
      },
      {
        path: "room",
        element: <Room />,
      },
    ],
  },
  // {
  //   path: "*",
  //   element: <NotFound />,
  // },
];

export default routes;
