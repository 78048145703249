import handleApi from "../utils/api";

export const saveNotes = async (roomId, teamMemberId, notes, identity) => {
  const data = {
    caller_id: roomId,
    updated_by: teamMemberId,
    message: notes,
    identity
  };

  const res = await handleApi(`/client-notes`, "POST", data);
  return res;
};
