import { CardMedia, Grid } from "@material-ui/core";
import React, { useRef, useEffect } from "react";

const Home = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        // console.log("stream:", stream);
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      })
      .catch((error) => {
        console.error("Error accessing camera:", error);
      });
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          {/* <video
            src="https://www.youtube.com/watch?v=oxFr7we3LC8"
            autoPlay
            loop
            muted
            style={{ width: "100%", height: "100%" }}
          /> */}
          <video ref={videoRef} autoPlay></video>
        </Grid>
        <Grid item xs={4}>
          <h1>Callee</h1>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
