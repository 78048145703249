import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentTimeStamp: new Date().getTime(),
  identity: "",
  isRoomHost: false,
  connectOnlyWithAudio: false,
  roomId: null,
  participants: [],
  localStream: null,
  streams: [],
  micState: true,
  camState: true,
  screenShareActive: false,
  screenShareStream: null,
  screenSharedBy: null,
  chatHistory: [],
  newNotification: 0,
  fromUrl: "",
  appName: "",
  callerType: "",
  repPlayerId: 0,
  attendeeToken: "",
  callPicked: 0,
  teamMemberId: 0,
  callEnded: 0,
  notes: "",
  calleeEmail: "",
  calleePhone: "",
  hasMic: true,
};

const slice = createSlice({
  name: "room",
  initialState,
  reducers: {
    setRoomHost: (state, action) => {
      state.isRoomHost = action.payload;
    },
    setConnectOnlyWithAudio: (state, action) => {
      state.connectOnlyWithAudio = action.payload;
    },
    setRoomId: (state, action) => {
      state.roomId = action.payload;
    },
    setIdentity: (state, action) => {
      state.identity = action.payload;
    },
    setShowLoader: (state, action) => {
      state.showLoader = action.payload;
    },
    setParticipants: (state, action) => {
      state.participants = action.payload;
    },
    setLocalStream: (state, action) => {
      state.localStream = action.payload;
    },
    setStreams: (state, action) => {
      const currentStreamObj = {
        stream: action.payload.stream,
        socketId: action.payload.connectedUserSocketId,
      };
      const temp = [...state.streams, currentStreamObj];
      state.streams = temp;
    },
    setScreenShareStream: (state, action) => {
      state.screenShareStream = action.payload;
    },
    setChatHistory: (state, action) => {
      const temp = [...state.chatHistory, action.payload];
      state.chatHistory = temp;
    },
    setNewNotification: (state, action) => {
      const temp = action.payload == 1 ? state.newNotification + 1 : 0;
      state.newNotification = temp;
    },
    removeStream: (state, action) => {
      const temp = state.streams.filter(
        (streamObj) => streamObj.socketId !== action.payload
      );
      state.streams = temp;
    },
    toggleMic: (state, action) => {
      state.micState = action.payload;
    },
    toggleCam: (state, action) => {
      state.camState = action.payload;
    },
    toggleScreenShare: (state, action) => {
      state.screenShareActive = action.payload;
    },
    setScreenSharedBy: (state, action) => {
      state.screenSharedBy = action.payload;
    },
    setFromUrl: (state, action) => {
      state.fromUrl = action.payload;
    },
    setCallerType: (state, action) => {
      state.callerType = action.payload;
    },
    setAppName: (state, action) => {
      state.appName = action.payload;
    },
    setRepPlayerId: (state, action) => {
      state.repPlayerId = action.payload;
    },
    setAttendeeToken: (state, action) => {
      state.attendeeToken = action.payload;
    },
    setCallPicked: (state, action) => {
      state.callPicked = action.payload;
    },
    setTeamMemberId: (state, action) => {
      state.teamMemberId = action.payload;
    },
    setCallEnded: (state, action) => {
      state.callEnded = action.payload;
    },
    setCalleeEmail: (state, action) => {
      state.calleeEmail = action.payload;
    },
    setCalleePhone: (state, action) => {
      state.calleePhone = action.payload;
    },
    getState: (state) => {
      return state;
    },
    getNotes: (state) => {
      return state.notes;
    },
    setMic: (state, action) => {
      state.hasMic = action.payload;
    },
    setNotes: (state, action) => {
      return (state = {
        ...state,
        notes: action.payload,
      });
    },
  },
});

export const { reducer } = slice;

export const setRoomHost = (isRoomHost) => (dispatch) => {
  dispatch(slice.actions.setRoomHost(isRoomHost));
};

export const setConnectOnlyWithAudio = (connectOnlyWithAudio) => (dispatch) => {
  dispatch(slice.actions.setConnectOnlyWithAudio(connectOnlyWithAudio));
};

export const setRoomId = (roomId) => (dispatch) => {
  dispatch(slice.actions.setRoomId(roomId));
};

export const setIdentity = (identity) => (dispatch) => {
  dispatch(slice.actions.setIdentity(identity));
};

export const setShowLoader = (showLoader) => (dispatch) => {
  dispatch(slice.actions.setShowLoader(showLoader));
};

export const setParticipants = (participants) => (dispatch) => {
  dispatch(slice.actions.setParticipants(participants));
};

export const setLocalStream = (localStream) => (dispatch) => {
  dispatch(slice.actions.setLocalStream(localStream));
};

export const setStreams = (stream, connectedUserSocketId) => (dispatch) => {
  dispatch(slice.actions.setStreams({ stream, connectedUserSocketId }));
};

export const setScreenShareStream = (screenShareStream) => (dispatch) => {
  dispatch(slice.actions.setScreenShareStream(screenShareStream));
};

export const setChatHistory = (chatHistory) => (dispatch) => {
  dispatch(slice.actions.setChatHistory(chatHistory));
};
export const setNewNotification = (newNotification) => (dispatch) => {
  dispatch(slice.actions.setNewNotification(newNotification));
};

export const removeStream = (connectedUserSocketId) => (dispatch) => {
  dispatch(slice.actions.removeStream(connectedUserSocketId));
};

export const getState = () => (dispatch) => {
  return dispatch(slice.actions.getState());
};

export const toggleMic = (micState) => (dispatch) => {
  dispatch(slice.actions.toggleMic(micState));
};

export const toggleCam = (camState) => (dispatch) => {
  dispatch(slice.actions.toggleCam(camState));
};

export const toggleScreenShare = (screenShareActive) => (dispatch) => {
  dispatch(slice.actions.toggleScreenShare(screenShareActive));
};

export const setScreenSharedBy = (screenSharedBy) => (dispatch) => {
  dispatch(slice.actions.setScreenSharedBy(screenSharedBy));
};

export const setFromUrl = (fromurl) => (dispatch) => {
  dispatch(slice.actions.setFromUrl(fromurl));
};

export const setAppName = (appName) => (dispatch) => {
  dispatch(slice.actions.setAppName(appName));
};

export const setCallerType = (callerType) => (dispatch) => {
  dispatch(slice.actions.setCallerType(callerType));
};

export const setRepPlayerId = (repPlayerId) => (dispatch) => {
  dispatch(slice.actions.setRepPlayerId(repPlayerId));
};

export const setAttendeeToken = (attendeeToken) => (dispatch) => {
  dispatch(slice.actions.setAttendeeToken(attendeeToken));
};

export const setCallPicked = () => (dispatch) => {
  dispatch(slice.actions.setCallPicked(1));
};

export const setTeamMemberId = (teamMemberId) => (dispatch) => {
  dispatch(slice.actions.setTeamMemberId(teamMemberId));
};

export const setCalleeEmail = (email) => (dispatch) => {
  dispatch(slice.actions.setCalleeEmail(email));
};

export const setCalleePhone = (phone) => (dispatch) => {
  dispatch(slice.actions.setCalleePhone(phone));
};

export const setCallEnded = () => (dispatch) => {
  dispatch(slice.actions.setCallEnded(1));
};

export const getNotes = () => (dispatch) => {
  return dispatch(slice.actions.getNotes());
};

export const setNotes = (notes) => (dispatch) => {
  dispatch(slice.actions.setNotes(notes));
};

export const setMic = (hasMic) => (dispatch) => {
  dispatch(slice.actions.setMic(hasMic));
};