import {
  removeStream,
  setLocalStream,
  setShowLoader,
  toggleCam,
  toggleMic,
  setMic
} from "../slices/roomSlice";
import store from "./../store/index";
import * as wss from "./wss";
import Peer from "simple-peer";
import { setStreams } from "./../slices/roomSlice";
import { has } from "lodash";
let hasCamera = false;
let hasMic = true;

const defaultConstraints = {
  audio: true,
  video: true,
};

const onlyAudioConstraints = {
  audio: true,
  video: false,
};

let localStream;

// Function to add an empty video track
function addEmptyVideoTrack(stream) {
  try {
    const canvas = document.createElement("canvas");
    canvas.width = 640; // Set the desired width and height
    canvas.height = 480;

    const canvasStream = canvas.captureStream(30); // 30 FPS, adjust as needed
    const [videoTrack] = canvasStream.getVideoTracks();

    // console.log("Video befor track", stream);
    // console.log("Video track", stream.getVideoTracks());
    // console.log("Audio track", stream.getAudioTracks());

    // Add the empty video track to the user's stream
    stream.addTrack(videoTrack);

    // console.log("Video after track", stream);
    // console.log("Video track", stream.getVideoTracks());
    // console.log("Audio track", stream.getAudioTracks());
  } catch (error) {
    // console.log("Error occured when adding empty video track", error);
  }
}


// Function to add an empty audio track
function addEmptyAudioTrack(stream) {
  try {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const oscillator = audioContext.createOscillator();
    const destination = audioContext.createMediaStreamDestination();
    oscillator.connect(destination);
    oscillator.start();
    const [audioTrack] = destination.stream.getAudioTracks();
    stream.addTrack(audioTrack);
  } catch (error) {
    console.error("Error occurred when adding empty audio track", error);
  }
}

export const getLocalPreviewAndInitRoomConnection = async (data) => {
  navigator.mediaDevices
    .enumerateDevices()
    .then((devices) => {
      // Filter devices to find video input devices (cameras)
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );

      const audioDevices = devices.filter(
        (device) => device.kind === "audioinput"
      );

      if (audioDevices.length === 0) {
        console.log("No audio devices found");
        hasMic = false;
        store.dispatch(setMic(false));
      }

      if (videoDevices.length > 0) {
        console.log("Camera found");
        // At least one camera is available
        hasCamera = true;
      } else {
        console.log("No camera found");
        // No camera is available
        hasCamera = false;
      }

      if (!hasMic && !hasCamera) {
        // Neither mic nor camera is available, handle the case here
        // You can create empty tracks and proceed or show a message to the user
        localStream = new MediaStream();
        addEmptyAudioTrack(localStream);
        addEmptyVideoTrack(localStream);
        showLocalPreview(localStream);

        store.dispatch(setShowLoader(false));
        data.isRoomHost
          ? wss.createNewRoom(data)
          : wss.joinRoom(data.roomId, data.identity, data.attendee_token);
        return; // Skip further processing
      }
    })
    .then(() => {
      const constraints = {
        audio: hasMic,
        video: hasCamera,
      };

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          store.dispatch(setShowLoader(false));

          localStream = stream;

          if (!hasCamera) {
            addEmptyVideoTrack(localStream); // Add an empty video track if no camera
          }

          if (!hasMic) {
            addEmptyAudioTrack(localStream); // Add an empty audio track if no microphone
          }

          showLocalPreview(stream);

          if (data.onlyWithAudio) {
            toggleVideo();
          }
          data.isRoomHost
            ? wss.createNewRoom(data)
            : wss.joinRoom(data.roomId, data.identity, data.attendee_token);
        })
        .catch((error) => {
          store.dispatch(setShowLoader(false));
          console.error("Error occurred in getting local stream", error);
        });
    })
    .catch((error) => {
      console.error("Error enumerating devices:", error);
    });
};


let peers = {};
const getConfiguration = () => {
  return {
    iceServers: [
      {
        urls: "stun:stun.l.google.com:19302",
      },
      {
        urls: "turn:3.142.183.117:3478",
        username: "iconnect",
        credential: "Iconnect@123",
      },
    ],
  };
};

export const prepareNewPeerConnection = (
  connectedUserSocketId,
  isInitiator
) => {
  try {
    const configuration = getConfiguration();

    peers[connectedUserSocketId] = new Peer({
      initiator: isInitiator,
      config: configuration,
      stream: localStream,
    });

    // console.log("Local stream", localStream);
    // console.log("Local stream video tracks", localStream.getVideoTracks());
    // console.log("Local stream audio tracks", localStream.getAudioTracks());
    // console.log("Local stream tracks", localStream.getTracks());

    peers[connectedUserSocketId].on("signal", (signal) => {
      // console.log("Peer signal", signal);
      const signalData = {
        connectedUserSocketId,
        signal,
      };

      wss.signalPeerData(signalData);
    });


    peers[connectedUserSocketId].on("stream", (stream) => {


      // console.log("Peer stream", stream);
      addStream(stream, connectedUserSocketId);
      store.dispatch(setStreams(stream, connectedUserSocketId));
    });
  } catch (error) {
    console.log("Error occured when preparing new peer connection", error);
  }
};

export const handleSinalingData = (data) => {
  try {
    // console.log("Handle signaling data", data);
    const { connectedUserSocketId, signal } = data;
    peers[connectedUserSocketId].signal(signal);
  } catch (error) {
    // console.log("Error occured when handling signaling data", error);
  }
};

export const removePeerConnection = (data) => {
  try {
    const { socketId } = data;
    // console.log("Remove peer connection", socketId);

    const videoEl = document.getElementById(socketId);
    if (videoEl) {
      const tracks = videoEl.srcObject.getTracks();

      tracks.forEach((t) => t.stop());

      videoEl.srcObject = null;
      videoEl.parentNode.removeChild(videoEl);
      if (peers[socketId]) {
        // peers[socketId].destroy();
      }
      delete peers[socketId];
      store.dispatch(removeStream(socketId));
    }

    // Check participants length if 0 then close the window
    const { participants, isRoomHost } = store.getState().room;
    if (isRoomHost) {
      if (participants.length < 3) {
        setTimeout(() => {
          // console.log("Close window");
          // // console.log("bik close initiated2");
          window.close();
        }, 5000);
      }
    }
  } catch (error) {
    // console.log("Error occured when removing peer connection", error);
  }
};

const addStream = (stream, connectedUserSocketId) => {
  // console.log("Add stream", stream);
};

const showLocalPreview = (stream) => {
  store.dispatch(setLocalStream(stream));
  // console.log("Show local preview", stream);
};

export const toggleMicrophone = () => {
  const isMicEnabled = localStream.getAudioTracks()[0].enabled;
  const storeLocalStream = store.getState().room.localStream;
  store.dispatch(toggleMic(!isMicEnabled));
  storeLocalStream.getAudioTracks()[0].enabled = !isMicEnabled;
};

export const toggleVideo = () => {
  // console.log("localStream", localStream);
  // console.log(localStream?.getVideoTracks());
  if (localStream?.getVideoTracks().length > 0) {
    const isVideoEnabled = localStream?.getVideoTracks()[0]?.enabled || false;
    const storeLocalStream = store.getState().room.localStream;
    store.dispatch(toggleCam(!isVideoEnabled));
    storeLocalStream.getVideoTracks()[0].enabled = !isVideoEnabled;
    wss.updateCameraStatus(!isVideoEnabled);
  }
};

export const toggleScreenShare = (
  isScreenSharingActive,
  screenSharingStream = null
) => {
  wss.notifyRoomParticipantsForScreenShare(isScreenSharingActive);
  if (!isScreenSharingActive) {
    // console.log("Switch to local stream");
    switchVideoTracks(localStream);
  } else {
    // console.log("Switch to screen sharing stream");
    switchVideoTracks(screenSharingStream);
  }
};

const switchVideoTracks = (stream) => {
  try {
    // console.log("Switch video tracks", peers);
    for (let socket_id in peers) {
      for (let index in peers[socket_id].streams[0].getTracks()) {
        for (let index2 in stream.getTracks()) {
          if (
            peers[socket_id].streams[0].getTracks()[index].kind ===
            stream.getTracks()[index2].kind
          ) {
            // console.log("User had camera");
            peers[socket_id].replaceTrack(
              peers[socket_id].streams[0].getTracks()[index],
              stream.getTracks()[index2],
              peers[socket_id].streams[0]
            );
            break;
          } else {
            // console.log(peers[socket_id]);
          }
        }
      }
    }
  } catch (error) {
    // console.log("Error occured when switching video tracks", error);
  }
};

const nonCameraScreenShare = async (screenShareStream) => {
  // Create a new peer to share screen
  const configuration = getConfiguration();
  const peer = new Peer({
    initiator: true,
    config: configuration,
    stream: screenShareStream,
  });

  // Send signal to other participants
  peer.on("signal", (signal) => {
    // console.log("Peer signal", signal);
    const signalData = {
      signal,
    };

    wss.signalPeerData(signalData);
  });

  // Add stream to local video
  peer.on("stream", (stream) => {
    // console.log("Peer stream", stream);
    addStream(stream, "screen-share");
    store.dispatch(setStreams(stream, "screen-share"));
  });

  // Store peer connection
  peers["screen-share"] = peer;
};
