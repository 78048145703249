import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { setNotes } from "../slices/roomSlice";
import { useSelector, useDispatch } from "../store";
import { saveNotes } from "./../api/notes";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const Notes = ({ showNotesDialogue, setShowNotesDialogue }) => {
  const { roomId, teamMemberId, notes, identity } = useSelector((state) => {
    return state.room;
  });
  const [tempNote, setTempNote] = useState(notes);
  const dispatch = useDispatch();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // console.log("tempNote", tempNote);
      dispatch(setNotes(tempNote));
      saveToDB(tempNote);
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [tempNote]);

  const handleClose = () => {
    if (tempNote !== notes) {
      dispatch(setNotes(tempNote));
      saveToDB(tempNote);
    }
    setShowNotesDialogue(false);
  };

  const handleChange = (e) => {
    setTempNote(e.target.value);
  };

  const saveToDB = async (notes) => {
    await saveNotes(roomId, teamMemberId, notes, identity);
  };

  return (
    <Dialog
      open={showNotesDialogue}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperComponent={PaperComponent}
      scroll="paper"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        Notes
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="notes"
          minRows={7}
          onChange={handleChange}
          value={tempNote}
          multiline
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Notes;
