import React, { useState, useEffect, useRef } from 'react';

const Timer = () => {
    const [time, setTime] = useState(0);
    const intervalRef = useRef(null);
    const startTimeRef = useRef(Date.now());

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            const elapsed = Math.floor((Date.now() - startTimeRef.current) / 1000);
            setTime(elapsed);
        }, 1000);

        // Cleanup on unmount
        return () => clearInterval(intervalRef.current);
    }, []);

    const formatTime = (seconds) => {
        const minutes = String(Math.floor(seconds / 60)).padStart(2, '0');
        const secs = String(seconds % 60).padStart(2, '0');
        return `${minutes}:${secs}`;
    };

    return (
        <div>
            {formatTime(time)}
        </div>
    );
};

export default Timer;
