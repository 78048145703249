import { Box, Button } from "@material-ui/core";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "../../../../store";
import { inviteMember } from "./../../../../api/room";

const SingleTeamMemberInvite = ({ teamMember }) => {
  const { roomId, teamMemberId } = useSelector((state) => state.room);
  const [disbaleButton, setDisbaleButton] = useState(false);

  const inviteTeamMember = async () => {
    setDisbaleButton(true);
    const res = await inviteMember(
      roomId,
      teamMemberId,
      teamMember.team_member_id
    );
    if (res.success) {
      toast.success("Invitation Sent");
      disableButtonForThirtySeconds();
    } else {
      toast.error("Invitation Failed");
      setDisbaleButton(false);
    }
  };

  const disableButtonForThirtySeconds = () => {
    setDisbaleButton(true);
    setTimeout(() => {
      setDisbaleButton(false);
    }, 30000);
  };

  return (
    <Box
      key={teamMember.team_member_id}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        p: 1,
        border: "1px solid #ccc",
        borderRadius: "5px",
      }}
    >
      <Box
        x={{
          pr: 1,
        }}
      >
        {teamMember.first_name} {teamMember.last_name}
      </Box>
      <Box>
        <Button
          color="primary"
          onClick={inviteTeamMember}
          disabled={disbaleButton}
        >
          {disbaleButton ? "Invited" : "Invite"}
        </Button>
      </Box>
    </Box>
  );
};

export default SingleTeamMemberInvite;
