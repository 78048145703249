import { pipeline } from "@xenova/transformers";
import store from "../store/index";
import { saveSentimentData } from './wss';
// Set up the sentiment analysis pipeline
let classifier = null;

const loadSentimentModel = async () => {
    if (!classifier) {
        classifier = await pipeline('text-classification', 'Xenova/distilbert-base-uncased-finetuned-sst-2-english');
    }
};

// Function to perform sentiment analysis
export const analyzeSentiment = async (text) => {
    if (!classifier) {
        await loadSentimentModel();
    }

    try {
        const result = await classifier(text);
        console.log("Sentiment Analysis Result: ", result);
        const callId = store.getState().room.roomId;
        saveSentimentData(callId, text, 1)

        return result;
    } catch (error) {
        console.error("Error performing sentiment analysis: ", error);
    }
};
