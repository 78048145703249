import React from "react";
import { useState } from "react";
import { useSelector } from "../../../../store";
import { useEffect } from "react";
import { getAllTeamMembers } from "../../../../api/room";
import { Box, Button, Typography } from "@material-ui/core";
import SingleTeamMemberInvite from "./SingleTeamMemberInvite";

const TeamMembers = () => {
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    getTeamMembers();
  }, []);

  const getTeamMembers = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const callerId = urlParams.get("callid");
    if (callerId) {
      const res = await getAllTeamMembers(callerId);
      setTeamMembers(res.data);
    }
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 1, py: 2, pl: 1 }}>
        Invite Team Members
      </Typography>
      {teamMembers.map((teamMember) => (
        <SingleTeamMemberInvite teamMember={teamMember} />
      ))}
    </Box>
  );
};

export default TeamMembers;
