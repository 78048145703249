import handleApi from "../utils/api";

export const getRoomExitsAPI = async (roomId) => {
  const res = await handleApi(`/room/room-exists/${roomId}`, "GET");
  return res;
};

export const getAllTeamMembers = async (callerId) => {
  const res = await handleApi(`/team-members/${callerId}`, "GET");
  return res;
};

export const inviteMember = async (
  roomId,
  teamMemberSenderId,
  teamMemberReceiverId
) => {
  const data = {
    caller_id: roomId,
    inviting_team_member_id: teamMemberSenderId,
    team_member_id: teamMemberReceiverId,
    email: "",
  };
  const res = await handleApi(`/call/invite-team-member`, "POST", data);
  return res;
};
export const onlyLeaveRoom = async (callId) => {
  const data = {
    call_id: callId,
  };
  const res = await handleApi(`/call/make-is-only-recording`, "POST", data);
  return res;
};
