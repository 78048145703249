import React, { useState } from "react";
import { Box, Button, IconButton, Typography } from "@material-ui/core";
import { Cast, StopScreenShare } from "@material-ui/icons";
import { useDispatch, useSelector } from "../../../../store";
import {
  setScreenShareStream,
  toggleScreenShare,
} from "../../../../slices/roomSlice";
import * as webRTCHandler from "../../../../utils/webRTCHandler";
import { useEffect } from "react";

const constraints = {
  video: {
    cursor: "always",
  },
  Audio: false,
};

const SwitchToScreenShareButton = () => {
  const { screenShareActive, screenShareStream, screenSharedBy } = useSelector(
    (state) => state.room
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (screenShareActive) {
      webRTCHandler.toggleScreenShare(screenShareActive, screenShareStream);
    } else {
      webRTCHandler.toggleScreenShare(screenShareActive);
    }
  }, [screenShareActive]);

  const handleScreenShare = async () => {
    try {
      if (!screenShareActive) {
        const stream = await navigator.mediaDevices.getDisplayMedia(
          constraints
        );
        if (stream) {
          stream.getVideoTracks()[0].addEventListener("ended", () => {
            dispatch(toggleScreenShare(false));
            dispatch(setScreenShareStream(null));
          });
          dispatch(setScreenShareStream(stream));
          dispatch(toggleScreenShare(true));
        }
      } else {
        // console.log(screenShareStream);
        screenShareStream.getTracks().forEach((track) => track.stop());
        dispatch(toggleScreenShare(false));
        dispatch(setScreenShareStream(null));
      }
    } catch (error) {
      // console.log("error while getting screen share stream", error);
    }
  };

  const checkIfSharingActiveForCurrentUser = () => {

    // console.log("screenShareActive", screenShareActive);
    // console.log("screenShareStream", screenShareStream);
    // console.log("screenSharedBy", screenSharedBy);

    if (screenSharedBy) {
      return false;
    }
    return true;
  }

  return (
    <Box
    >
      {/* Disable the button if screen is not shread by the user himself */}
      {checkIfSharingActiveForCurrentUser() ? <Button
        sx={{
          color: "white",
          boxShadow: "none",
        }}
        variant="contained"
        onClick={handleScreenShare}
      >
        <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
          {screenShareActive ? <StopScreenShare /> : <Cast />}
          <Typography sx={{ mt: 0, fontSize: "12px" }}>
            {screenShareActive ? "Stop Sharing" : "Share Screen"}
          </Typography>
        </Box>
      </Button> : ""}

    </Box>
  );
};

export default SwitchToScreenShareButton;
