import React, { useEffect } from "react";
import { Box, Button, IconButton, Typography } from "@material-ui/core";
import { Mic, MicOff } from "@material-ui/icons";
import { useSelector } from "../../../../store";
import { toggleMicrophone } from "./../../../../utils/webRTCHandler";
import { updateMute } from "./../../../../utils/wss";
import { handleMuteUnmute } from "./../../../../utils/audioRecorder";

const MicButton = () => {
  const { micState, participants, roomId } = useSelector((state) => state.room);

  const handleMicButtonPressed = () => {
    toggleMicrophone();
    updateMute({ roomId });
  };

  useEffect(() => {
    handleMuteUnmute(!micState);
  }, [micState]);

  return (
    <Box
    >
      <Button
        sx={{
          color: "white",
          boxShadow: "none",
        }}
        variant="contained"
        onClick={handleMicButtonPressed}
      >
        <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
          {!micState ? <MicOff /> : <Mic />}
          <Typography sx={{ mt: 0, fontSize: "12px" }}>
            {micState ? "Mute" : "Unmute"}
          </Typography>
        </Box>

      </Button>
    </Box>
  );
};

export default MicButton;
