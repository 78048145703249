import { Box, Button, Drawer, Typography } from "@material-ui/core";
import { Share } from "@material-ui/icons";
import React from "react";
import TeamMembers from "./TeamMembers";

const InviteButton = () => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => {
    // console.log("toggleDrawer");
    setState({ ...state, [anchor]: open });
  };

  return (
    <Box
    >
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            onClick={() => toggleDrawer("right", true)}
            sx={{
              color: "white",
              boxShadow: "none",
            }}
            variant="contained"
          >
            <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
              <Share />
              <Typography sx={{ mt: 0, fontSize: "12px" }}>
                Invite
              </Typography>
            </Box>
          </Button>
          <Drawer
            anchor="right"
            open={state["right"]}
            onClose={() => toggleDrawer("right", false)}
          >
            <TeamMembers />
          </Drawer>
        </React.Fragment>
      ))}
    </Box>
  );
};

export default InviteButton;
