import { combineReducers } from "@reduxjs/toolkit";

import { reducer as roomReducer } from "../slices/roomSlice";
import { reducer as recorderReducer } from "../slices/recorderSlice";

const rootReducer = combineReducers({
  room: roomReducer,
  recorder: recorderReducer,
});

export default rootReducer;
