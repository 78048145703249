import React, { useState, useEffect } from "react";
import { Videocam, VideocamOff } from "@material-ui/icons";
import { Box, Button, Typography } from "@material-ui/core";
import { useSelector } from "../../../../store";
import { toggleVideo } from "../../../../utils/webRTCHandler";

const CameraButton = () => {
  const { camState } = useSelector((state) => state.room);
  const [hasCamera, setHasCamera] = useState(true);

  const cameraButtonPressed = () => {
    toggleVideo();
  };

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      // Filter devices to find video input devices (cameras)
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );

      if (videoDevices.length > 0) {
        setHasCamera(true);
      } else {
        setHasCamera(false);
      }
    });
  }, []);

  return (
    <>
      {hasCamera ? (
        <Box
        >
          <Button
            sx={{
              color: "white",
              boxShadow: "none",

            }}
            variant="contained"
            onClick={cameraButtonPressed}
          >
            <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
              {camState ? <Videocam /> : <VideocamOff />}
              <Typography sx={{ mt: 0, fontSize: "12px" }}>
                {camState ? "Stop Video" : "Start Video"}
              </Typography>
            </Box>

          </Button>
        </Box>
      ) : (
        ""
      )}
    </>
  );
};

export default CameraButton;
