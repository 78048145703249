import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  AUDIO_S3_FOLDER_NAME: new Date().getTime(),
};

const slice = createSlice({
  name: "recorder",
  initialState,
  reducers: {
    audioFolderName: (state, action) => {
      state.AUDIO_S3_FOLDER_NAME = action.payload;
    },
  },
});

export const { reducer } = slice;

export const setS3AudioFolderName = (name) => (dispatch) => {
  dispatch(slice.actions.setRoomHost(name));
};
