import Notes from "../components/Notes";
import handleApi from "../utils/api";

export const createSR = async (
    roomId,
    teamMemberId,
    notes,
    identity
  ) => {
    const data = {
      caller_id: roomId,
      updated_by: teamMemberId,
      message: notes,
      callPickedUpBy: identity
    };
  
    const res = await handleApi(
      "/create-sr",
      "POST",
      data
    );
    return res;
  };