import { Box, Dialog, Typography, Button, DialogActions } from '@material-ui/core'
import { Info } from '@material-ui/icons'
import React, { useState } from 'react'
import Video from '../../../../videos/iConnect.mp4'

const TutorialButton = () => {
    const [openTutorial, setOpenTutorial] = useState(false)

    const handleCloseTutorial = () => {
        setOpenTutorial(false)
    }
    return (
        <>
            <Dialog open={openTutorial} onClose={handleCloseTutorial} fullWidth>
                <Box sx={{ p: 2 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Screen Sharing Guide
                    </Typography>
                    {/* Show video */}
                    <video
                        src={Video}
                        width="100%"
                        height="auto"
                        controls
                    />
                </Box>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={handleCloseTutorial}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Box
            >
                <Button
                    sx={{
                        color: "white",
                        boxShadow: "none",

                    }}
                    variant="contained"
                    onClick={() => setOpenTutorial(true)}
                >
                    <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <Info />
                        <Typography sx={{ mt: 0, fontSize: "12px" }}>
                            Guide
                        </Typography>
                    </Box>

                </Button>
            </Box>
        </>
    )
}

export default TutorialButton